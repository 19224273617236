button,
.button {
  border: none;
  cursor: pointer;
  transition: all 350ms;
}
button:hover,
.button:hover {
  filter: brightness(125%);
}

.button {
  height: 32px;
  padding: 10px 24px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 0;
  text-decoration: none;
  color: var(--col-p-800);
  background-color: var(--col-p-400);
  border-radius: 4px;
}
.button-small {
  height: 24px;
  padding: 0 12px;
}
.button-large {
  height: 40px;
}
.button-secondary {
  color: var(--col-p-200);
  background-color: var(--col-p-700);
}
.button-secondary:hover {
  filter: brightness(90%);
}
.button-outline {
  color: var(--col-p-700);
  background: none;
  border: solid 1px var(--col-p-700);
}
.button-wide-icon {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: auto;
  padding: 8px 16px;
  color: var(--col-p-600);
  border-radius: 0;
  background-color: transparent;
  transition: background-color 200ms;
}
.button-wide-icon:hover {
  background-color: var(--col-p-200);
}
.button:visited {
  color: unset;
}

/* TODO: make separate theme vars */
:root {
  --col-r-100: #990000;
  --col-r-200: #cc0f0f;
  --col-r-300: #f43939;
  --col-r-400: #f46565;
  --col-r-500: #ff8d92;
  --col-r-600: #ffc5c5;
  --col-r-700: #ffdddd;
  --col-r-800: #ffe9e9;
  --col-g-100: #008261;
  --col-g-200: #1da581;
  --col-g-300: #3cbb93;
  --col-g-400: #1dd1a1;
  --col-g-500: #4ee1b9;
  --col-g-600: #81efce;
  --col-g-700: #94f2d5;
  --col-g-800: #b4f8e4;
  --col-b-100: #03468a;
  --col-b-200: #2372c3;
  --col-b-300: #70b1f4;
  --col-b-400: #8ec1ff;
  --col-b-500: #b4d6ff;
  --col-b-600: #cee4fb;
  --col-b-700: #dbecff;
  --col-b-800: #e6f0fb;
  --col-p-100: #1a1a32;
  --col-p-200: #2c2c54;
  --col-p-300: #464686;
  --col-p-400: #6c6cb2;
  --col-p-500: #9494c7;
  --col-p-600: #b0b0d4;
  --col-p-700: #cacae2;
  --col-p-800: #f2f2f8;
}

.column {
  /* Default to medium width */
  width: var(--timeline-width-medium);
  flex-shrink: 0;
  background-color: var(--col-p-100);
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Inter Regular"), local("Inter-Regular"), url("/fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Inter Medium"), local("Inter-Medium"), url("/fonts/Inter-Medium.ttf") format("truetype");
}
input[type=checkbox] {
  width: 16px;
  height: 16px;
  margin: 0;
  accent-color: var(--col-p-400);
}

input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  margin-top: 0;
  background-color: var(--col-p-700);
  border: solid 2px var(--col-p-400);
  border-radius: 50%;
  transition: all 150ms;
}
input[type=radio]:checked {
  border: solid 6px var(--col-p-400);
}

input[type=number] {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  text-transform: none;
  color: var(--col-p-700);
  max-width: 100px;
  padding: 6px 12px;
  color: var(--col-p-200);
  background-color: var(--col-p-700);
  border: none;
  border-radius: 6px;
}
input[type=number]:disabled {
  color: var(--col-p-100);
  background-color: #929292;
}

select {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  text-transform: none;
  color: var(--col-p-700);
  padding: 6px 12px;
  color: var(--col-p-200);
  background-color: var(--col-p-700);
  border: none;
  border-radius: 6px;
}
select:disabled {
  color: var(--col-p-100);
  background-color: #929292;
}

fieldset {
  width: fit-content;
  margin: 0;
  margin-top: 8px;
  padding-right: 32px;
  border-color: var(--col-p-300);
  border-width: 2px;
  border-radius: 6px;
}
fieldset > :first-of-type {
  margin-top: 2px !important;
}

/* Misc UI variables. */
:root {
  /* Horizontal padding for timeline header and statuses.  */
  --timeline-h-padding: 16px;
  /* Timeline width options. */
  --timeline-width-small: 240px;
  --timeline-width-medium: 320px;
  --timeline-width-large: 480px;
}

.vertical-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: scroll;
}
.vertical-list .list-item {
  --item-height: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: background-color 200ms;
}
.vertical-list .list-item.selected, .vertical-list .list-item:hover {
  background-color: var(--col-p-200);
}
.vertical-list .list-item .selected-indicator {
  flex: 0 1 4px;
  height: var(--item-height);
  background-color: transparent;
  transition: all 200ms;
}
.vertical-list .list-item.selected .selected-indicator {
  background-color: var(--col-p-700);
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: var(--col-p-200);
}

body,
.body-text {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  text-transform: none;
  color: var(--col-p-700);
}

h1 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 48px;
  line-height: 1.2;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

h2 {
  font-family: "Inter";
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
}

a {
  color: var(--col-b-300);
}
a:visited {
  color: var(--col-p-600);
}

textarea {
  resize: vertical;
  margin: 0;
  padding: 8px;
  font-family: unset;
  color: var(--col-p-100);
  background-color: var(--col-p-700);
  border: none;
  border-radius: 4px;
}