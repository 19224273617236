section.settings-section {
  width: var(--timeline-width-large);
  padding: 12px 16px;
  background-color: var(--col-p-100);
}
section.settings-section h1 {
  margin: 0;
  font-size: 20px;
}
section.settings-section section {
  margin-top: 20px;
}
section.settings-section section h2 {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  text-transform: none;
  color: var(--col-p-700);
  margin: 0;
  font-weight: 500;
}
section.settings-section section p {
  max-width: 85%;
  margin: 0;
  margin-top: 8px;
}
section.settings-section section label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
section.settings-section section label.vertical {
  max-width: 85%;
  flex-direction: column;
  align-items: start;
}